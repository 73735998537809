import React from "react"
import { graphql, PageProps } from "gatsby"

import { Query } from "@graphql-types"
import { SEO, Header, Ctas, DownloadBlock } from "@global"
import BlogsSection from "@components/shared/blogsSection"
import NewsletterBlock from "@components/shared/newsletter/newsletterBlock"

interface Props extends PageProps {
  data: Query
}

const BlogTemplate = ({ data }: Props) => {
  if (data.sanityGuide == null) return null

  const {
    thankYouSeo,
    file,
    thankYouPageTitle,
    ctas,
    downloadButtonText,
  } = data.sanityGuide

  return (
    <div className="subpage-container">
      <SEO data={thankYouSeo} />
      <Header
        title={thankYouPageTitle}
        buttonText={downloadButtonText}
        downloadUrl={file?.asset?.url}
      />
      {ctas && <Ctas data={ctas} />}
      <NewsletterBlock />
      <BlogsSection />
      {file && <DownloadBlock data={file} />}
    </div>
  )
}

export const query = graphql`
  query singleGuideThankYouQuery($slug: String) {
    sanityGuide(thankYouSlug: { current: { eq: $slug } }) {
      thankYouPageTitle
      thankYouSlug {
        current
      }
      thankYouSeo {
        ...sanitySeo
      }
      downloadButtonText
      file {
        asset {
          url
          originalFilename
        }
      }
      ctas {
        ...sanityCtas
      }
    }
  }
`

export default BlogTemplate
